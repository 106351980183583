import React from "react";
import { useState, useEffect, useContext } from "react";
import {
    Typography,
    Stack,
    Grid,
    CircularProgress,
    Paper,
    Box,
    Alert,
    Card,
    CardContent,
    Tooltip,
    IconButton,
    Modal,
    Fab,
    InputAdornment,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import fetchUserResults from "../../services/transformResultData";
import HomeIcon from "@mui/icons-material/Home";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import EuroIcon from "@mui/icons-material/Euro";
import SavingsIcon from "@mui/icons-material/Savings";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import TollOutlinedIcon from "@mui/icons-material/TollOutlined";
import { createGermanNumberString } from "../../utils/formatting";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import SouthIcon from "@mui/icons-material/South";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { FloatInput } from "../../components/InputFields";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RadialBarChart from "../../components/RadialBarChart";
import AuthContext from "../../contexts/AuthContext";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "220px",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    })
);

/**
 * A React functional component that renders the home page of the EnergyARK application.
 *
 * This component displays various cards with information about energy consumption, CO2 emissions, potential savings,
 * and other relevant data. It fetches user results from a backend service and uses them to populate the UI elements.
 * The component also includes a modal for changing the energy price and a radial bar chart to visualize data.
 *
 * Returns:
 *   ReactElement: A component that displays the EnergyARK home page with various data visualizations and information.
 */
const Home = () => {
    const [userResults, setUserResults] = useState(false);
    const [loading, setLoading] = useState(true);
    const [energyPrice, setEnergyPrice] = useState(0.1); // 10 cents
    const [modalOpen, setModalOpen] = useState(false);
    const [energyPriceInput, setEnergyPriceInput] = useState({ real: 10, display: "10" });
    const [userResultYearIdx, setUserResultYearIdx] = useState(0);
    const [userPreferedYear, setUserPreferedYear] = useState(0);

    const [availableUserResultYears, setAvailableUserResultYears] = useState([]);
    const [radialBarChartData, setRadialBarChartData] = useState([]);

    const { logoutUser } = useContext(AuthContext);

    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    // fetch data from backend
    useEffect(() => {
        (async () => {
            fetchUserResults({
                filter_type: "all",
                filter_choice: [],
            }).then((response) => {
                setUserPreferedYear(response.preferredYear);
                if (response.userResults.length > 0) {
                    setUserResultYearIdx(0);
                    setUserResults(response.userResults);
                }
                setLoading(false);
            }).catch((error) => {
                // check for unauthorized error
                if (error.message === "Request failed with status code 403") {
                logoutUser();
                }
                else{console.error(error);}

            });
        })();
        }, []);


    useEffect(() => {
        if (!userResults || availableUserResultYears.length === 0) {
            return;
        }
        var res = null;
        if (userPreferedYear === null) {
            res = availableUserResultYears.reduce(
                (max, item) => (item.year > max.year ? item : max),
                availableUserResultYears[0]
            ).idx;
            setUserResultYearIdx(res);
        }

        setRadialBarChartData(getRadialBarChartData(res ? res : userResultYearIdx));
    }, [userResults, availableUserResultYears]);

    useEffect(() => {
        if (!userResults) {
            return;
        }
        var years = [];
        userResults.forEach((elem, idx) => {
            if (elem.total_living_space > 0) {
                if (elem.year === userPreferedYear) {
                    setUserResultYearIdx(idx);
                }
                years.push({ year: elem.year, idx: idx });
            }
        });
        setAvailableUserResultYears(years);
        //setUserResultYearIdx(userResults.user_preferred_year);
    }, [userResults]);

    const getRadialBarChartData = (idx) => {
        if (idx === null) {
            return [];
        }
        return [
            {
                id: "Wohnfläche [%]",
                data: [
                    {
                        x: "Kritisch",
                        y: userResults[idx].result_category_living_space_pct.CRITICAL * 100,
                    },
                    {
                        x: "Hoch",
                        y: userResults[idx].result_category_living_space_pct.BAD * 100,
                    },
                    {
                        x: "Normal",
                        y: userResults[idx].result_category_living_space_pct.NORMAL * 100,
                    },
                    {
                        x: "Niedrig",
                        y: userResults[idx].result_category_living_space_pct.GOOD * 100,
                    },
                ],
            },
            {
                id: "Endenergieverbrauch [%]",
                data: [
                    {
                        x: "Kritisch",
                        y: userResults[idx].result_category_energy_consumption_pct.CRITICAL * 100,
                    },
                    {
                        x: "Hoch",
                        y: userResults[idx].result_category_energy_consumption_pct.BAD * 100,
                    },
                    {
                        x: "Normal",
                        y: userResults[idx].result_category_energy_consumption_pct.NORMAL * 100,
                    },
                    {
                        x: "Niedrig",
                        y: userResults[idx].result_category_energy_consumption_pct.GOOD * 100,
                    },
                ],
            },
            {
                id: "CO\u2082 Emissionen [%]",
                data: [
                    {
                        x: "Kritisch",
                        y: userResults[idx].result_category_co2_emissions_pct.CRITICAL * 100,
                    },
                    {
                        x: "Hoch",
                        y: userResults[idx].result_category_co2_emissions_pct.BAD * 100,
                    },
                    {
                        x: "Normal",
                        y: userResults[idx].result_category_co2_emissions_pct.NORMAL * 100,
                    },
                    {
                        x: "Niedrig",
                        y: userResults[idx].result_category_co2_emissions_pct.GOOD * 100,
                    },
                ],
            },
        ];
    };

    const YearSelection = () => {
        const handleChange = (event) => {
            setUserResultYearIdx(event.target.value); // I think this is unnecessary - SIMON,
            // value={userResultYearIdx} already keeps the value of the state variable in sync. But don't know enough about React to be sure

            setRadialBarChartData(getRadialBarChartData(event.target.value));
        };

        return (
            <FormControl size="small" sx={{ minWidth: 120, height: 30 }}>
                <InputLabel>Jahr auswählen</InputLabel>
                <Select value={userResultYearIdx} onChange={handleChange} label="Jahr auswählen" defaultValue={0}>
                    {availableUserResultYears.map((item) => (
                        <MenuItem value={item.idx} key={item.year}>
                            {item.year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const EnergyPriceChangeModal = (
        <>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="energyprice-change-modal"
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        setEnergyPrice(energyPriceInput.real / 100);
                        handleModalClose();
                    }
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 465,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="energyprice-change-modal" variant="h6" component="h2" sx={{ mb: 1 }}>
                        {"Geben Sie den gewünschten Arbeitspreis an."}
                    </Typography>
                    <FloatInput
                        label="Arbeitspreis"
                        value={energyPriceInput}
                        validate={(val) => {
                            if (val > 200) {
                                return false;
                            } else {
                                return true;
                            }
                        }}
                        error={false}
                        setError={() => {}}
                        sx={{ width: "100%", mt: 1 }}
                        onChange={(val) => setEnergyPriceInput(val)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{"ct/kWh"}</InputAdornment>,
                        }}
                    ></FloatInput>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={(e) => handleModalClose()}
                        sx={{ mt: 1.8, ml: "5%", width: "42.5%", boxShadow: 1, borderRadius: 1 }}
                    >
                        Abbrechen
                    </Fab>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={(e) => {
                            setEnergyPrice(energyPriceInput.real / 100);
                            handleModalClose();
                        }}
                        color="secondary"
                        sx={{ mt: 1.8, ml: "5%", width: "42.5%", boxShadow: 1, borderRadius: 1 }}
                    >
                        <SaveIcon /> Speichern
                    </Fab>
                </Box>
            </Modal>
        </>
    );

    const BaseDataCard = userResults ? (
        <Box maxWidth="64ch" m="1ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Datengrundlage
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontSize: 15,
                            fontWeight: "bold",
                            mt: 2,
                        }}
                        color="text.secondary"
                    >
                        <CalendarMonthIcon
                            sx={{ mb: "-12px", mr: 1, justifyContent: "center", alignItems: "center" }}
                        />
                        <YearSelection />
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <HomeIcon sx={{ mb: "-5px", mr: 1 }} />
                        {userResults[userResultYearIdx].num_buildings} Gebäude{" "}
                        <CustomTooltip title="An dieser Stelle werden nur die Gebäude und die Wohnfläche einbezogen, für die im oben genannten Jahr Ergebnisse vorliegen.">
                            <InfoIcon color="neutral" sx={{ ml: 1, mb: "-6px" }} />
                        </CustomTooltip>
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <ApartmentIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_apartments)} Wohneinheiten
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <Grid4x4Icon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_living_space) + "m\u00B2 "}{" "}
                        Wohnfläche
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const CarbonEmissionsCard = userResults ? (
        <Box m="1ch" maxWidth="64ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {"CO\u2082 Bilanz"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <LocalFireDepartmentIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_co2_emissions) +
                            "t Emissionen p.a."}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <FireplaceIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_co2_emissions_per_sqm) +
                            "kg Emissionen pro m\u00B2\u2217a"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <SolarPowerIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_pv_co2_savings) +
                            "t Einsparung durch PV p.a."}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const CarbonLandlordCostsCard = userResults ? (
        <Box m="1ch" maxWidth="64ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {"CO\u2082 Vermieterkosten"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <EuroIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_co2_costs_landlord) +
                            "\u20ac Kosten p.a."}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <TollOutlinedIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_co2_costs_landlord_per_sqm) +
                            "\u20ac Kosten pro m\u00B2\u2217a"}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontSize: 15,
                            mt: 2,
                            fontWeight: "bold",
                        }}
                        color="text.secondary"
                    >
                        <SavingsIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(
                            userResults[userResultYearIdx].total_potential_savings_co2_costs_landlord
                        ) + "\u20ac Einsparpotential p.a."}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <SavingsOutlinedIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(
                            userResults[userResultYearIdx].total_potential_savings_co2_costs_landlord_per_sqm
                        ) + "\u20ac Einsparpotential pro m\u00B2\u2217a"}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const EnergyConsumptionCard = userResults ? (
        <Box m="1ch" maxWidth="64ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {"Energieverbrauch"}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontSize: 15,
                            mt: 2,
                            fontWeight: "bold",
                        }}
                        color="text.secondary"
                    >
                        <LocalFireDepartmentIcon
                            sx={{
                                mb: "-5px",
                                mr: 1,
                            }}
                        />
                        {createGermanNumberString(userResults[userResultYearIdx].total_gross_energy_consumption) +
                            "MWh Endenergieverbrauch p.a."}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <FireplaceIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(
                            userResults[userResultYearIdx].total_gross_energy_consumption_per_sqm
                        ) + "kWh Endenergieverbrauch pro m\u00B2\u2217a"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <EnergySavingsLeafIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(userResults[userResultYearIdx].total_potential_savings_consumption) +
                            "MWh Einsparpotential p.a."}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <EnergySavingsLeafOutlinedIcon sx={{ mb: "-5px", mr: 1 }} />
                        {createGermanNumberString(
                            userResults[userResultYearIdx].total_potential_savings_consumption_per_sqm
                        ) + "kWh Einsparpotential pro m\u00B2\u2217a"}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const BuildingConsumptionOverviewCard = userResults ? (
        <Box m="1ch" width="73ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {"Gebäudeverbrauchsübersicht"}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontSize: 15,
                            mt: 2,
                            fontWeight: "bold",
                        }}
                        color="text.secondary"
                    >
                        <DangerousOutlinedIcon
                            sx={{
                                mb: "-5px",
                                mr: 1,
                                color: "#ff1745",
                            }}
                        />
                        {userResults[userResultYearIdx].result_category_counts.CRITICAL + "\u2217 kritischer Verbrauch"}
                        <CustomTooltip title="Der angegebene Verbrauch des Gebäudes liegt weit über dem erwarteten Wert für den zugehörigen Gebäudezustand. Mit hoher Wahrscheinlichkeit liegt schwerwiegendes Nutzerfehlverhalten oder eine Fehlkonfiguration der Heizungsanlage vor.">
                            <InfoIcon color="neutral" sx={{ ml: 1, mb: "-6px" }} />
                        </CustomTooltip>
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <WarningAmberOutlinedIcon sx={{ mb: "-5px", mr: 1, color: "#ff9e17" }} />
                        {userResults[userResultYearIdx].result_category_counts.BAD + "\u2217 hoher Verbrauch"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <ThumbUpAltOutlinedIcon sx={{ mb: "-5px", mr: 1, color: "#49ad2d" }} />
                        {userResults[userResultYearIdx].result_category_counts.NORMAL + "\u2217 normaler Verbrauch"}
                    </Typography>
                    <Typography sx={{ textAlign: "left", fontSize: 15, mt: 2 }} color="text.secondary">
                        <SouthIcon sx={{ mb: "-5px", mr: 1, color: "#032C58" }} />
                        {userResults[userResultYearIdx].result_category_counts.GOOD + "\u2217 sehr niedriger Verbrauch"}
                        <CustomTooltip title="Der angegebene Verbrauch des Gebäudes liegt deutlich unter dem erwarteten Wert für den zugehörigen Gebäudezustand. In den meisten Fällen ist dies unbedenklich, doch kann zu geringes Heizen/Lüften auch beispielsweise zu Schimmel führen. Allerdings können zu hohe Erwartungswerte auch auftreten, wenn der angegebene Modernisierungszustand des Gebäudes nicht mehr aktuell ist.">
                            <InfoIcon color="neutral" sx={{ ml: 1, mb: "-6px" }} />
                        </CustomTooltip>
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const TotalSavingsPotentialCard = userResults ? (
        <Box m="1ch" maxWidth="73ch">
            <Card sx={{ minHeight: "28ch" }}>
                <CardContent>
                    <Typography variant="h3" sx={{ mb: 4 }}>
                        {"Gesamtes Einsparpotential"}
                    </Typography>
                    <Grid container>
                        <Box minWidth="30%">
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    mb: 1,
                                }}
                                color="text.secondary"
                            >
                                <SavingsIcon sx={{ fontSize: 25, mb: "-4px", mr: 2 }} />
                                {createGermanNumberString(
                                    userResults[userResultYearIdx].total_potential_savings_co2_costs
                                ) + "\u20ac"}
                            </Typography>
                        </Box>
                        <Box maxWidth="70%" ml={2} sx={{ textAlign: "left" }}>
                            <Typography sx={{ fontSize: 17 }}>
                                {"kombiniertes Potential CO\u2082 Kosten p.a."}
                                <CustomTooltip
                                    title={
                                        "Aufgrund der Stufenmechanik des CO2KostAufG kann das Gesamteinsparpotential kleiner sein als das Einsparpotential an CO\u2082-Kosten für den Vermieter. Der Mieter spart aber immer aufgrund der eingesparten Energiekosten."
                                    }
                                >
                                    <InfoIcon color="neutral" sx={{ ml: 1, mb: "-6px" }} />
                                </CustomTooltip>
                            </Typography>
                        </Box>
                        <Box minWidth="30%" maxWidth="50%" mt={2}>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: 20,
                                    mt: 2,
                                    fontWeight: "bold",
                                }}
                                color="text.secondary"
                            >
                                <EnergySavingsLeafIcon sx={{ fontSize: 25, mb: "-4px", mr: 2 }} />

                                {createGermanNumberString(
                                    userResults[userResultYearIdx].total_potential_savings_consumption *
                                        1000 *
                                        energyPrice
                                ) + "\u20ac"}
                            </Typography>
                        </Box>
                        <Box maxWidth="70%" mt={3} ml={2} sx={{ textAlign: "left" }}>
                            <Typography sx={{ fontSize: 17 }}>{" Potential Energieverbrauch p.a."}</Typography>
                            <div>
                                <Typography sx={{ fontSize: 13 }}>
                                    {"bei Arbeitspreis von " + createGermanNumberString(energyPrice * 100) + "ct/kWh"}
                                    <IconButton
                                        aria-label="edit"
                                        onClick={handleModalOpen}
                                        sx={{
                                            fontSize: 10,
                                            mt: -0.2,
                                            ml: 0.2,
                                            boxShadow: 1,
                                            borderColor: "blue",
                                            border: 0.5,
                                        }}
                                        size="small"
                                        color="secondary"
                                    >
                                        <EditIcon sx={{ fontSize: 11 }} />
                                    </IconButton>
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    const RadialBarChartCard = userResults ? (
        <Box m="1ch">
            <Card sx={{ height: "56.9ch", minWidth: "55ch" }}>
                <CardContent>
                    <RadialBarChart data={radialBarChartData} />
                </CardContent>
            </Card>
        </Box>
    ) : (
        <></>
    );

    return (
        <div>
            {EnergyPriceChangeModal}
            <Grid container>
                <Box width="59%"></Box>
                <Box>
                    <img src={"/Logo_ohne_Hintergrund.png"} alt="AdvisoRE Logo" width="500" />
                </Box>
            </Grid>
            {!loading ? (
                <Stack maxWidth="160ch" minHeight="130ch" spacing={3} mb={3} data-testid="results-stack">
                    <>
                        <Item sx={{ height: "50%" }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    display: "flex",
                                    m: 1,
                                }}
                            >
                                {"Ihre EnergyARK Auswertung auf einen Blick:"}
                            </Typography>

                            {userResults && availableUserResultYears.length > 0 ? (
                                <Box maxWidth="100%">
                                    <Grid container data-testid="results-sub-grid" sx={{ mt: 3 }}>
                                        {BaseDataCard}
                                        {CarbonEmissionsCard}
                                        {CarbonLandlordCostsCard}
                                        {EnergyConsumptionCard}
                                        <Box>
                                            {BuildingConsumptionOverviewCard}
                                            {TotalSavingsPotentialCard}
                                        </Box>
                                        {RadialBarChartCard}
                                    </Grid>
                                </Box>
                            ) : (
                                <Box
                                    width="100%"
                                    height="100%"
                                    sx={{
                                        display: "flex",
                                        marginLeft: "5ch",
                                        marginTop: "5ch",
                                        marginBottom: "5ch",
                                        // marginRight: "10%",
                                    }}
                                    data-testid="no-results-box"
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "12ch", fontSize: 14 }}>
                                        <strong>Es liegen noch keine Ergebnisse vor.</strong>
                                        <br />
                                        Fügen Sie unter "Gebäudedetails" ein erstes Gebäude
                                        <br />
                                        inklusive Verbrauchsdaten und Gebäudezustand
                                        <br />
                                        hinzu, um Ergebnisse zu sehen.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Item>
                        <Item>
                            <Typography variant="h3" sx={{ textAlign: "left", m: 2 }}>
                                Was ist der Hintergrund?
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
                                Als Eigentümer werden Sie ab 2023 gemäß CO2KostAufG (CO2-Kostenaufteilungsgesetz)
                                anteilig, je nach Höhe der Emissionen, an den verbrauchsbasierten CO2-Kosten beteiligt.
                                Hier besteht ein hohes Einsparpotential, durch baulich investive und nicht investive
                                Maßnahmen. <br /> <br />
                                Zudem werden Unternehmen zukünftig nach EU-Verordnung verpflichtet einen Report zu
                                erstellen. Hierfür frühzeitig CO2-Bilanzen zu erstellen, hilft Ihnen nachzuvollziehen wo
                                Ihr Unternehmen steht und welche Entwicklungen es in der Vergangenheit gab. Daraus
                                ziehen Sie wichtige Informationen über Schwächen, Probleme und Potenziale für
                                Einsparungen aber auch erfolgreiche Maßnahmen. <br /> <br />
                                Außerdem können Sie erfassen, ob Sie eine zielkonforme Entwicklung eingeschlagen haben,
                                die den Vorgaben der EU und Bundesregierung entspricht. Die EnergyARK hilft Ihnen so
                                einen effizienten Weg zu finden, um Ihren Bestand zukunftsfähig und resilient
                                aufzustellen. Gerne bieten wir Ihnen bei Bedarf auch weiterführende Beratung auf Basis
                                Ihrer Daten in der Plattform zum IST-Zustand Ihres Bestands sowie möglichen Maßnahmen
                                an. Nehmen Sie hierfür Kontakt zu uns auf:{" "}
                                <a href="mailto:info@AdvisoRE.eu">info@AdvisoRE.eu</a>.
                            </Typography>
                        </Item>
                    </>
                </Stack>
            ) : (
                <Box
                    width="100%"
                    height="100%"
                    sx={{
                        display: "flex",
                        marginLeft: "10%",
                        marginTop: "10%",
                        marginBottom: "10%",
                        marginRight: "10%",
                    }}
                    data-testid="loading-box"
                >
                    <CircularProgress size={150} color="secondary" data-testid="loading-spinner" />
                </Box>
            )}
        </div>
    );
};

export default Home;
